<template>
    <div>
        <div
            class="modal"
            id="SignInModal"
            style="display: block; background-color: rgba(0, 0, 0, 0.4)"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog modals-newtheme" role="document">
                <div class="modal-content">
                    <div class="modal-body p-6">
                        <button
                            v-on:click="
                                $parent.showSignIn = !$parent.showSignIn
                            "
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div class="row">
                            <div class="col-md-12 col-12">
                                <div class="text-center">
                                    <a href="/">
                                        <picture>
                                            <img
                                                src="@/assets/images/logo.svg"
                                                alt="Flowers"
                                                style="width: 200px"
                                            />
                                        </picture>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12 col-12">
                                    <div>
                                        <form
                                            @submit.prevent="validateForm"
                                            action="/login"
                                            method="post"
                                        >
                                            <input
                                                type="hidden"
                                                name="_token"
                                                v-model="credentials.token"
                                            />
                                            <div class="mt-2">
                                                <label class="font_size"
                                                    >Email or Username</label
                                                >
                                                <input
                                                    type="text"
                                                    v-model="credentials.email"
                                                    name="email"
                                                    placeholder="Email or Username"
                                                    class="form-control"
                                                    v-on:focus="
                                                        errorMessages.email = []
                                                    "
                                                />
                                                <ul
                                                    class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                                    v-if="
                                                        errorMessages.email
                                                            .length > 0
                                                    "
                                                >
                                                  <!-- eslint-disable -->
                                                    <li
                                                        v-for="err in errorMessages.email"
                                                    >
                                                        <small>{{ err }}</small>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="mt-2">
                                                <label class="font_size"
                                                    >Password</label
                                                >
                                                <input
                                                    type="Password"
                                                    v-model="
                                                        credentials.password
                                                    "
                                                    name="password"
                                                    placeholder="Enter Password"
                                                    class="form-control"
                                                    v-on:focus="
                                                        errorMessages.password =
                                                            []
                                                    "
                                                />
                                                <ul
                                                    class="text-sm text-danger text-red-600 text-xs italic mt-1"
                                                    v-if="
                                                        errorMessages.password
                                                            .length > 0
                                                    "
                                                >
                                                  <!-- eslint-disable -->
                                                    <li
                                                        v-for="err in errorMessages.password"
                                                    >
                                                        <small>{{ err }}</small>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="forgot text-right">
                                                <a
                                                    href="javascript:void(0)"
                                                    v-on:click="
                                                        $parent.resetPassword()
                                                    "
                                                    >Forgot Password</a
                                                >
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12 col-12">
                                                    <button
                                                        type="submit"
                                                        width="100%"
                                                        class="btn btn-primary width_login"
                                                        :disabled="!!loading"
                                                    >
                                                        <div
                                                            v-if="!!loading"
                                                            class="spinner-border text-primary"
                                                            style="
                                                                color: white !important;
                                                                width: 20px !important;
                                                                height: 20px !important;
                                                            "
                                                            role="status"
                                                        ></div>
                                                        <span
                                                            v-if="
                                                                !!loading ==
                                                                false
                                                            "
                                                            >Log In</span
                                                        >
                                                    </button>
                                                </div>
                                                <div class="continue">
                                                    <a href="javascript:void(0)"
                                                        >or</a
                                                    >
                                                </div>

<!--                                                <div class="media-btns">-->
<!--                                                    <a-->
<!--                                                        href="/auth/google"-->
<!--                                                        class="btn btn-primary go"-->
<!--                                                    >-->
<!--                                                        <img-->
<!--                                                            width="25px"-->
<!--                                                            src="@/assets/images/Google_Icon.webp"-->
<!--                                                        />-->
<!--                                                        Continue with Google-->
<!--                                                    </a>-->
<!--                                                </div>-->
                                              <GoogleSignInButton
                                                  @success="handleGoogleLoginSuccess"
                                                  @error="handleGoogleLoginError"
                                                  width="400"
                                              ></GoogleSignInButton>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {GlobalEventBus} from "@/assets/js/eventBus";
import endpoints from "@/assets/js/api";
import axiosInstance from "@/assets/js/axios";
import {toast} from "vue3-toastify";
import {GoogleSignInButton} from "vue3-google-signin";

export default {
    name: "SignInModalComponent",
  components: {GoogleSignInButton},
    props: ["rname"],
    data() {
        return {
            errorLabel: false,
            errorMessage: "",
            credentials: {
                email: "",
                password: "",
                token: "",
            },
            errorMessages: {
                username: [],
                email: [],
                password: [],
            },
            loading: false,
        };
    },
    mounted() {},
    created() {
        let token = document.head.querySelector('meta[name="csrf-token"]');
        if (token) this.credentials.token = token.content;
    },
    methods: {
      async handleGoogleLoginSuccess(response) {

        const credential = response.credential;
        this.loading = true;

        if(!credential) {
          toast.error("Login Failed");
        }

        try {

          let req_data = {access_token : credential};

          const { data } = await axiosInstance.post(endpoints.handleGoogleToken, req_data);

          if (data.status === true) {
            self.parent.showSignUp = !self.parent.showSignUp;
            GlobalEventBus.emit("authentication");
          }

        } catch (e) {
          toast.error("Login Failed");
        }

        this.loading = false;
      },
      handleGoogleLoginError() {
        toast.error("Login Failed");
      },

        checkEmail() {
            this.errorMessages.email = [];
            if (!this.credentials.email) {
                this.errorMessages.email.push("Please provide Email or Username");
                return false;
            } else {
                this.errorMessages.email = [];
                return true;
            }
        },
        checkPassword() {
            this.errorMessages.password = [];
            if (!this.credentials.password) {
                this.errorMessages.password.push("Password");
                return false;
            } else {
                this.errorMessages.password = [];
                return true;
            }
        },
        validateForm() {
            // this.checkEmail();
            // this.checkPassword();
            // if (this.checkEmail() && this.checkPassword()) {
                this.login();
            // }
        },
        async login() {
            let self = this;
            let email = this.credentials.email;
            let password = this.credentials.password;
            let formData = new FormData();
            formData.append("password", password);
            formData.append("email", email);
            this.loading = true;

            try {

              const { data } = await axiosInstance.post(endpoints.loginRoute, formData);
              this.loading = false;

              if (data.status === true) {

                GlobalEventBus.emit("authentication");
                GlobalEventBus.emit("refreshcart");

                self.parent.showSignIn = false;
                toast.success("Log In Successful!");

                if (data.data.user.active_role === "tutor") {
                  if (
                      self.rname === "tutor.list" ||
                      self.rname === "course.list" ||
                      self.rname === "tutor.detail" ||
                      self.rname === "course.detail" ||
                      self.rname === "home"
                  ) {
                    window.location.href =
                        "/" + data.data.active_role + "/dashboard";
                  }
                }
              }

            } catch (err) {

              this.loading = false;
              let res = err.response.data;

              if (res.type === "validation") {

                Object.keys(res.errors).forEach(function (key) {
                  self.errorMessages[key] = res.errors[key];
                });

              } else {
                this.errorMessages.email.push(res.message);
              }
            }



        },
    },
};
</script>

<style scoped>
.select {
    background: #4e97d2;
    color: white;
}
.select:focus {
    outline: 1px solid #4e97d2;
}
.set {
    display: flex;
}
.dont_account {
    font-size: 17px;
}
.dateOfB {
    font-size: 17px;
    margin-top: 20px;
}
.dont_account .sign a {
    color: #4e97d2b5;
    font-weight: 300;
}
.width_login {
    margin-top: 20px;
    width: 100%;
}
.forgot {
    margin-top: 14px;
    font-size: 17px;
}
.forgot a {
    color: #4e97d2b5;
    font-weight: 300;
}
.margin_top {
    margin-top: 30px;
}
.font_size {
    font-size: 16px;
}
.login {
    color: black;
}
.logo_cover {
    padding: 22px;
}
.modal-header {
    border: none;
}
</style>
