<template>
  <footer class="page-footer font-small footer_bg lighten-">
    <!-- Footer Links -->
    <div class="container text-center text-md-left footer-container">
      <div class="row mt-3 dark-grey-text">
        <div class="col-md-3 col-lg-4 col-xl-3 mb-4">
          <router-link to="/">
            <picture>
              <source media="(max-width:767px)" srcset="@/assets/images/logo.svg">
              <img src="@/assets/images/logo.svg" width="200" height="46" alt="ILMZone Logo desktop">
            </picture>
          </router-link>
          <p class="footer-label-t">Your Ultimate Learning Tool. Your Ultimate Learning Companion. Your Ultimate Educational Partner. Your Perfect Straight-of-the-art Learning Partner</p>
        </div>
        <div class="col-sm-6 col-md-2 col-lg-2 col-xl-2 mx-auto mb-4 text-left">
          <h6 class="text-uppercase font-weight-bold footer-heading">Resources</h6>
          <p class="footer-links">
            <router-link to="/policy/how-to-refund" class="dark-grey-text">How to refund</router-link>
          </p>
          <p class="footer-links">
            <router-link to="/about-us" class="dark-grey-text">About Us</router-link>
          </p>
          <p class="footer-links">
            <router-link to="/faq" class="dark-grey-text">FAQ</router-link>
          </p>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-2 col-xl-2 mx-auto mb-4 text-left">
          <h6 class="text-uppercase font-weight-bold footer-heading">Support</h6>
          <p class="footer-links">
            <router-link to="/contact-us" class="dark-grey-text">Contact Us</router-link>
          </p>
          <p class="footer-links">
            <router-link to="/terms-condition" class="dark-grey-text">Terms & Conditions</router-link>
          </p>
          <p class="footer-links">
            <router-link to="/privacy" class="dark-grey-text">Privacy</router-link>
          </p>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
          <h6 class="text-uppercase font-weight-bold footer-heading">Subscribe To Our Education Blog</h6>
          <form @submit.prevent="subscribe">
            <input v-model="email" class="form-control f_name footer-input" type="email" required placeholder="Your email address">
            <button class="footer_btn btn btn-primary subscribe-btn" type="submit">Subscribe</button>
          </form>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
  data() {
    return {
      email: '' // To store the email input value
    };
  },
  methods: {
    subscribe() {
      // Implement your subscription logic here
      console.log('Subscribe:', this.email);
      // Example: You can call an API to handle subscription
      // Example: axios.post('/api/subscribe', { email: this.email })
      //            .then(response => {
      //                console.log('Subscribed successfully!');
      //            })
      //            .catch(error => {
      //                console.error('Subscription failed:', error);
      //            });
    }
  }
};
</script>

<style scoped>
/* Add your component-specific styles here */
</style>
