import emitter from 'tiny-emitter'

export const Chat = () => {
    window.Echo.private("chat")
        .listen("MessageSent", (e) => {
          emitter.emit("chatUpdate", e)
        })
        .listen("notification", (e) => {
          emitter.emit("newJobPublished", e)
        })

}
