// src/api.js
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

const endpoints = {
  signUp: `${API_BASE_URL}/register`,
  loginRoute: `${API_BASE_URL}/login`,
  handleGoogleToken: `${API_BASE_URL}/auth/google`,
  getUserDetailRoute: `${API_BASE_URL}/user/detail`,
};

export default endpoints;
