// src/axios.js
import axios from 'axios';

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 10000, // Optional timeout
  headers: {
    'Content-Type': 'application/json',
    // Add any custom headers here
  },
});

// Optional: Add a request interceptor
axiosInstance.interceptors.request.use(
  config => {
    // Do something before the request is sent
    return config;
  },
  error => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Optional: Add a response interceptor
axiosInstance.interceptors.response.use(
  response => {
    // Do something with response data
    return response;
  },
  error => {
    // Do something with response error
    return Promise.reject(error);
  }
);

export default axiosInstance;
