<template>
  <header class="header-style-01" id="header-sec">
    <div
        class="wrapper-overlay"
        :class="{ open: isMenuOpen }"
        @click="isMenuOpen = false"
    ></div>
    <div class="container container-desktop">
      <nav class="navbar navbar-expand-xl navbar-light">
        <a class="navbar-brand" href="/">
          <picture>
            <source
                media="(max-width:767px)"
                srcset="@/assets/images/logo.svg"
            />
            <img
                src="@/assets/images/logo.svg"
                class="logo-image"
                alt="ILM zone Logo desktop"
            />
          </picture>
        </a>
        <div class="mobile-menu-style-01">
          <ul class="navbar-nav mr-auto">
            <li
                class="nav-item"
                v-if="authenticated"
                :class="rname === 'dashboard' ? 'active' : ''"
            >
              <a
                  class="nav-link"
                  :href="'/' + role + '/dashboard'"
              >
                                <span class="mainnav-icons">
                                    <img
                                        src="@/assets/new-theme-images/dashboard_purple.png"
                                        class="inactive-img"
                                        alt="video icon"
                                        width="17"
                                        height="17"
                                    />
                                    <img
                                        width="20"
                                        height="15"
                                        src="@/assets/new-theme-images/white-courses.png"
                                        class="active-img"
                                        alt="video icon"
                                    />
                                </span>
                Dashboard
              </a>
            </li>
            <li
                v-if="role !== 'tutor'"
                class="nav-item"
                :class="
                                rname === 'course.list' ||
                                rname === 'course.detail' ||
                                rname === 'course.forum'
                                    ? 'active'
                                    : ''
                            "
            >
              <a class="nav-link" href="/courses">
                                <span class="mainnav-icons">
                                    <img
                                        width="20"
                                        height="15"
                                        src="@/assets/new-theme-images/video-icon.png"
                                        class="inactive-img"
                                        alt="video icon"
                                    />
                                    <img
                                        width="20"
                                        height="15"
                                        src="@/assets/new-theme-images/white-courses.png"
                                        class="active-img"
                                        alt="video icon"
                                    />
                                </span>
                Courses
              </a>
            </li>
            <li
                v-if="role !== 'tutor'"
                class="nav-item"
                :class="
                                rname === 'tutor.list' ||
                                rname === 'tutor.detail'
                                    ? 'active'
                                    : ''
                            "
            >
              <a class="nav-link" href="/tutors">
                                <span class="mainnav-icons">
                                    <img
                                        width="15"
                                        height="17"
                                        src="@/assets/new-theme-images/tutor-icon.png"
                                        class="inactive-img"
                                        alt="video icon"
                                    />
                                    <img
                                        width="16"
                                        height="20"
                                        src="@/assets/new-theme-images/tutor_icon_360.png"
                                        class="active-img"
                                        alt="video icon"
                                    />
                                </span>
                Tutors
              </a>
            </li>
            <li
                class="nav-item"
                :class="
                                rname === 'post.job' ||
                                rname === 'post.job.detail'
                                    ? 'active'
                                    : ''
                            "
            >
              <a class="nav-link" href="/post/job">
                                <span class="mainnav-icons">
                                    <i
                                        style="margin: 12px 6px"
                                        class="fa fa-chalkboard-teacher"
                                        aria-hidden="true"
                                    ></i>
                                </span>
                Tuitions
              </a>
            </li>
            <li
                class="nav-item dropdown multi-level-dropdown"
                :class="
                                rname === 'forum.detail' ||
                                rname === 'forum' ||
                                rname === 'forum.detail'
                                    ? 'active'
                                    : ''
                            "
            >
              <a
                  class="nav-link dropdown-toggle"
                  href="javascript:void(0)"
                  id="dropdown05"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
              >
                                <span class="mainnav-icons">
                                    <i
                                        style="margin: 10px 12px"
                                        class="fa fa-solid fa-bars"
                                        aria-hidden="true"
                                    ></i>
                                </span>
                Resources
                <img
                    width="20"
                    height="25"
                    src="@/assets/new-theme-images/arrow-drop-down.png"
                />
              </a>
              <div
                  class="dropdown-menu dropdown05 dropdown-content"
                  aria-labelledby="dropdown05"
              >
                <a
                    href="javascript:void(0)"
                    class="dropdown-toggle"
                    id="dropdown04"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                                    <span
                                        class="dropdown-item dropdown-item border-bottom"
                                    >
                                        Students
                                        <i
                                            class="fa fa-caret-right float-right"
                                            style="margin-top: 5px"
                                        ></i>
                                    </span>
                </a>
                <div
                    class="dropdown-menu sub-menu dropdown04"
                    style="top: 0; left: 230px"
                >
                  <a
                      class="dropdown-item dropdown-item border-bottom"
                      href="/faq"
                  >FAQ's</a
                  >
                  <a
                      class="dropdown-item dropdown-item border-bottom"
                      href="/policy/student/sign-up-process"
                  >Signup Process for Student</a
                  >
                </div>

                <a
                    href="javascript:void(0)"
                    class="dropdown-toggle"
                    data-toggle="dropdown"
                    id="dropdown03"
                >
                                    <span
                                        class="dropdown-item dropdown-item border-bottom"
                                    >Tutors
                                        <i
                                            class="fa fa-caret-right float-right"
                                            style="margin-top: 5px"
                                        ></i
                                        ></span>
                </a>
                <div
                    class="dropdown-menu sub-menu dropdown03"
                    aria-labelledby="dropdown03"
                    style="top: 40px; left: 230px"
                >
                  <a
                      class="dropdown-item dropdown-item border-bottom"
                      href="/tutor/faq"
                  >FAQ's</a
                  >
                  <a
                      class="dropdown-item dropdown-item border-bottom"
                      href="/policy/sign-up-process"
                  >Signup Process for Tutor</a
                  >
                  <a
                      class="dropdown-item dropdown-item border-bottom"
                      href="/policy/course-creation-process"
                  >Course Creation and Approval</a
                  >
                  <!--                  <a class="dropdown-item dropdown-item border-bottom" href="javascript:void(0)"> Payment Policy</a>-->
                </div>

                <a
                    href="javascript:void(0)"
                    class="dropdown-toggle"
                    data-toggle="dropdown"
                    id="dropdown02"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                                    <span
                                        class="dropdown-item dropdown-item border-bottom"
                                    >Policies
                                        <i
                                            class="fa fa-caret-right float-right"
                                            style="margin-top: 5px"
                                        ></i
                                        ></span>
                </a>
                <div
                    class="dropdown-menu sub-menu dropdown02"
                    aria-labelledby="dropdown03"
                    style="top: 74px; left: 230px"
                >
                  <a
                      class="dropdown-item dropdown-item border-bottom"
                      href="/policy/cancellation-rescheduling"
                  >Cancellation & Rescheduling</a
                  >
                  <a
                      class="dropdown-item dropdown-item border-bottom"
                      href="/policy/refund"
                  >Refund & Disputes</a
                  >
                </div>

                <a
                    href="javascript:void(0)"
                    class="dropdown-toggle"
                    data-toggle="dropdown"
                    id="dropdown09"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                                    <span
                                        class="dropdown-item dropdown-item border-bottom"
                                    >Blogs
                                        <i
                                            class="fa fa-caret-right float-right"
                                            style="margin-top: 5px"
                                        ></i
                                        ></span>
                </a>
                <div
                    class="dropdown-menu sub-menu dropdown09"
                    style="top: 110px; left: 230px"
                >
                  <a
                      class="dropdown-item dropdown-item border-bottom"
                      href="/blogs/top-5-helpful-tips"
                  >Top 5 Helpful Tips</a
                  >
                  <a
                      class="dropdown-item dropdown-item border-bottom"
                      href="/blogs/e-tutoring"
                  >E Tutoring</a
                  >
                </div>
                <a
                    v-if="authenticated"
                    href="/forum"
                    class="dropdown-toggle"
                    id="dropdown01"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                                    <span
                                        class="dropdown-item dropdown-item border-bottom"
                                    >Forum
                                      <!--                                  <i class="fa fa-caret-right float-right" style="margin-top: 5px;"></i>-->
                                    </span>
                </a>
              </div>
            </li>

            <li class="nav-item dropdown">
              <a
                  class="nav-link dropdown-toggle"
                  href="javascript:void (0)"
                  id="dropdown05"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
              >
                                <span class="mainnav-icons">
                                    <i
                                        style="margin: 10px 14px"
                                        class="fa fa-solid fa-info"
                                        aria-hidden="true"
                                    ></i>
                                </span>
                About Us
                <img
                    width="20"
                    height="25"
                    src="@/assets/new-theme-images/arrow-drop-down.png"
                /></a>
              <div
                  class="dropdown-menu dropdown-content"
                  aria-labelledby="dropdown05"
              >
                <a
                    class="dropdown-item dropdown-item border-bottom"
                    href="/about-us"
                >About Us</a
                >
                <a
                    class="dropdown-item dropdown-item border-bottom"
                    href="/contact-us"
                >Contact us</a
                >
              </div>
            </li>
          </ul>
        </div>
        <div class="d-flex">
          <div class="header-right-box">
            <div
                v-if="authenticated === true"
                class="dropdown newtheme-cart-section"
            >
              <div class="cart-icon has-alert">
                <a
                    class="position-relative cart_counter"
                    v-on:mouseover="notificationRead()"
                    href="javascript:void(0)"
                >
                  <i
                      class="fa fa-bell p-2"
                      style="color: #812bb2"
                  ></i>
                  <span
                      class="badge badge_color bell_badge"
                  >{{ unread }}</span
                  >
                </a>
                <div class="shopping-cart add_carts">
                  <div class="shopping-cart-header">
                    <div class="shopping-cart-total">
                                            <span class="lighter-text"
                                            >Notifications</span
                                            >
                    </div>
                  </div>
                  <div class="product-detail">
                    <ul
                        class="shopping-cart-items notifications"
                    >
                      <!-- eslint-disable -->
                      <li
                          v-for="notification in notifications"
                      >
                        <a
                            target="_blank"
                            :href="
                                                        notification.data.link
                                                    "
                            class="dropdown-item"
                            style="
                                                        justify-content: space-between;
                                                    "
                        >
                          <!--                    <i class="fa fa-envelope mr-2"></i> -->
                          <!-- eslint-disable -->
                          <span>{{
                              notification.data
                                  .message
                                  | cutText(30, "...")
                            }}</span>
                          <span
                              class="float-right text-muted text-sm"
                          >{{
                              notification.created_at
                                  | humanize
                            }}</span
                          >
                        </a>
                      </li>
                    </ul>
                    <a
                        href="/notifications"
                        class="text-center dropdown-item justify-content-center view-all-btn"
                    >View All</a
                    >
                  </div>
                </div>
              </div>
            </div>

            <div
                v-if="authenticated === true"
                class="ml-2 mr-1 mr-md-0 ml-md-2 user-profile_wrapper"
            >
              <div class="dropdown box-drop">
                <div class="img-thumbnail-left">
                  <img
                      width="45"
                      height="45"
                      class="rounded-circle dropbtn user_img dropdown-toggle"
                      :src="'/storage' + profile"
                      alt=""
                  />
                </div>
                <div class="user-info-right px-2">
                  <p class="user-name">
                    {{ userDetail.first_name }}
                    <i
                        v-if="userDetail.verified"
                        class="fa fa-check-circle"
                        style="color: #812bb2"
                    ></i>
                  </p>
                </div>
                <div class="dropdown-content">
                                    <span class="item-name">
                                        <a
                                            v-for="role in roles"
                                            :class="{
                                                active:
                                                    role.name ===
                                                    userDetail.active_role,
                                            }"
                                            class="dropdown-item border-bottom"
                                            :href="
                                                '/' + role.name + '/dashboard'
                                            "
                                        >{{
                                            role.name
                                                .charAt(0)
                                                .toUpperCase() +
                                            role.name.substring(1)
                                          }}</a
                                        >
                                        <a
                                            v-for="dependent in dependents"
                                            class="dropdown-item border-bottom"
                                            v-on:click="
                                                redirectToChildDashboard(
                                                    dependent.id
                                                )
                                            "
                                            href="javascript:void (0)"
                                        >{{
                                            dependent.name
                                          }}
                                            (Dependents)</a
                                        >
                                        <a
                                            class="dropdown-item border-bottom"
                                            :href="'/' + role + '/profile'"
                                        >Account Settings</a
                                        >
                                        <a
                                            v-on:click="logout()"
                                            href="javascript:void (0)"
                                            class="dropdown-item"
                                        >Logout</a
                                        >
                                    </span>
                </div>
              </div>
            </div>
            <button
                id="signin"
                v-if="authenticated === false"
                type="button"
                class="btn btn-sm btn-outline-primary ml-1 ml-sm-2 ml-md-3 header-signin-btn"
                v-on:click="showSignIn = !showSignIn"
            >
              Login
            </button>
            <button
                id="signup"
                v-if="authenticated === false"
                type="button"
                class="btn btn-sm btn-outline-primary ml-1 ml-sm-2 ml-md-3 header-signin-btn"
                v-on:click="showSignUp = !showSignUp"
            >
              Join
            </button>
          </div>

          <button
              class="navbar-toggler ml-2 ml-md-3 navbarIcon"
              type="button"
              aria-controls="navbarsExample05"
              aria-expanded="false"
              aria-label="Toggle navigation"
              @click="openMenu"
          >
            <img
                width="24"
                height="24"
                src="@/assets/new-theme-images/nav-icon.png"
                alt="nav icon"
            />
          </button>
        </div>
        <div id="menu" class="side-menu" :class="{ open: isMenuOpen }">
          <div class="mobile-sidebar">
            <div class="sidebar-links">
              <div class="close-button close-btn">
                <button @click="isMenuOpen = false">
                  <img
                      width="15"
                      height="15"
                      src="@/assets/new-theme-images/red-cross.png"
                      alt="close"
                  />
                </button>
              </div>
              <ul>
                <li v-if="role !== 'tutor'">
                  <a :href="'/' + role + '/dashboard'">
                                        <span class="l-img">
                                            <!--                      <img src="/new-theme-images/video-icon.png" alt="video" />-->
                                        </span>
                    <span v-if="authenticated" class="link"
                    >Dashboard</span
                    >
                  </a>
                </li>
                <li v-if="role !== 'tutor'">
                  <a href="/courses">
                                        <span class="l-img">
                                            <img
                                                width="20"
                                                height="15"
                                                src="@/assets/new-theme-images/video-icon.png"
                                                alt="video"
                                            />
                                        </span>
                    <span class="link">Courses</span>
                  </a>
                </li>

                <li v-if="role !== 'tutor'">
                  <a href="/tutors">
                                        <span class="l-img">
                                            <img
                                                width="15"
                                                height="17"
                                                src="@/assets/new-theme-images/tutor-icon.png"
                                                alt="tutor"
                                            />
                                        </span>
                    <span class="link">Tutors</span>
                  </a>
                </li>

                <li>
                  <a href="/post/job">
                                        <span class="l-img">
                                            <i
                                                class="fa fa-chalkboard-teacher"
                                                aria-hidden="true"
                                            ></i>
                                        </span>
                    <span class="link">Tuition</span>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                                        <span class="l-img">
                                            <img
                                                width="28"
                                                height="25"
                                                src="@/assets/new-theme-images/cart.png"
                                                alt="cart"
                                            />
                                        </span>
                    <span class="link">My Cart</span>
                  </a>
                </li>

                <li>
                  <a
                      href="javascript:void(0)"
                      id="resoursesMenu"
                      @click="
                                            openResoursesMenu =
                                                !openResoursesMenu;
                                            openStudentMenu = false;
                                            openPoliciesMenu = false;
                                            openTutorsMenu = false;
                                            openAboutMenu = false;
                                        "
                  >
                                        <span class="l-img">
                                            <img
                                                width="20"
                                                height="25"
                                                src="@/assets/new-theme-images/arrow-drop-down.png"
                                                alt="arrow"
                                            />
                                        </span>
                    <span class="link">Resourses</span>
                  </a>
                  <div
                      class="resoursesMenu"
                      :class="{ show: openResoursesMenu }"
                  >
                    <a
                        id="studentMenu"
                        class="dropdown-toggle"
                        role="button"
                        @click="
                                                openStudentMenu =
                                                    !openStudentMenu;
                                                openTutorsMenu = false;
                                                openPoliciesMenu = false;
                                            "
                    >
                                            <span
                                                class="dropdown-item dropdown-item border-bottom"
                                            >Students / Guardians
                                                <i
                                                    class="fa fa-caret-right float-right"
                                                    style="margin-top: 5px"
                                                ></i
                                                ></span>
                    </a>
                    <div
                        class="dropdown-menu sub-menu studentMenuDropdown"
                        :class="{ show: openStudentMenu }"
                    >
                      <a
                          class="dropdown-item dropdown-item border-bottom"
                          href="/faq"
                      >FAQ's</a
                      >
                      <a
                          class="dropdown-item dropdown-item border-bottom"
                          href="/policy/sign-up-process"
                      >Signup Process</a
                      >
                      <a
                          class="dropdown-item dropdown-item border-bottom"
                          href="policy/course-creation-process"
                      >Course Creation and Approval</a
                      >
                    </div>
                    <a
                        href="javascript:void(0)"
                        class="dropdown-toggle"
                        role="button"
                    >
                                            <span
                                                id="tutorsMenu"
                                                @click="
                                                    openTutorsMenu =
                                                        !openTutorsMenu;
                                                    openStudentMenu = false;
                                                    openPoliciesMenu = false;
                                                "
                                                class="dropdown-item dropdown-item border-bottom"
                                            >For Tutors
                                                <i
                                                    class="fa fa-caret-right float-right"
                                                    style="margin-top: 5px"
                                                ></i>
                                            </span>
                    </a>
                    <div
                        class="dropdown-menu sub-menu tutorsMenu"
                        :class="{ show: openTutorsMenu }"
                        aria-labelledby="dropdown03"
                    >
                      <a
                          class="dropdown-item dropdown-item border-bottom"
                          href="/tutor/faq"
                      >FAQ's</a
                      >
                      <a
                          class="dropdown-item dropdown-item border-bottom"
                          href="/policy/sign-up-process"
                      >Signup Process</a
                      >
                    </div>
                    <a
                        href="javascript:void(0)"
                        class="dropdown-toggle"
                        role="button"
                    >
                                            <span
                                                id="policiesMenu"
                                                @click="
                                                    openPoliciesMenu =
                                                        !openPoliciesMenu;
                                                    openTutorsMenu = false;
                                                    openStudentMenu = false;
                                                "
                                                class="dropdown-item dropdown-item border-bottom"
                                            >Policies
                                                <i
                                                    class="fa fa-caret-right float-right"
                                                    style="margin-top: 5px"
                                                ></i
                                                ></span>
                    </a>
                    <div
                        class="dropdown-menu sub-menu policiesMenu"
                        :class="{ show: openPoliciesMenu }"
                        aria-labelledby="dropdown03"
                    >
                      <a
                          class="dropdown-item dropdown-item border-bottom"
                          href="/policy/cancellation-rescheduling"
                      >Cancellation & Rescheduling</a
                      >
                    </div>
                    <a
                        href="/forum"
                        class="dropdown-toggle"
                        role="button"
                    ><span
                        class="dropdown-item dropdown-item border-bottom"
                    >Forum</span
                    ></a
                    >
                  </div>
                </li>
                <li>
                  <a
                      href="javascript:void(0)"
                      id="aboutMenu"
                      @click="
                                            openAboutMenu = !openAboutMenu;
                                            openResoursesMenu = false;
                                        "
                  >
                                        <span class="l-img"
                                        ><img
                                            width="20"
                                            height="25"
                                            src="@/assets/new-theme-images/arrow-drop-down.png"
                                            alt="arrow"
                                        /></span>
                    <span class="link">About</span>
                  </a>
                  <div
                      class="aboutMenu"
                      :class="{ show: openAboutMenu }"
                  >
                    <a href="/about-us" role="button"
                    ><span
                        class="dropdown-item border-bottom"
                    >About Us</span
                    ></a
                    >
                    <a href="/contact-us" role="button"
                    ><span
                        class="dropdown-item border-bottom"
                    >Contact us</span
                    ></a
                    >
                  </div>
                </li>

                <li>
                  <a href="javascript:void(0)">
                                        <span class="l-img">
                                            <i
                                                style="margin: 12px 6px"
                                                class="fa-solid fa-circle-question"
                                            ></i>
                                        </span>
                    <span class="link">How it works</span>
                  </a>
                </li>
              </ul>
            </div>
            <div class="sidebar-footer">
              <p>
                <a
                    v-if="authenticated"
                    :href="role + '/dashboard'"
                >Go to Dashboard</a
                >
              </p>
              <div class="profile-img">
                <img
                    v-if="authenticated"
                    width="40"
                    height="40"
                    :src="'/storage' + profile"
                    alt="user"
                />
              </div>
            </div>
          </div>
        </div>
      </nav>
      <!-- Sign In Modal -->
      <SignInModalComponent
          v-if="showSignIn"
          :rname="rname"
      ></SignInModalComponent>
      <!-- Sign Up Modal -->
      <SignUpModalComponent
          v-if="showSignUp"
          :signupRole="signupRole"
          :rname="rname"
      ></SignUpModalComponent>
      <!-- Chat User List -->
      <ChatUserListComponent v-if="authenticated"></ChatUserListComponent>
      <!-- Password Reset Modal -->
      <PasswordResetEmailComponent
          v-if="resetModal"
      ></PasswordResetEmailComponent>
    </div>
    <!-- Button trigger modal -->
  </header>
</template>

<script>
import { Notification } from "@/assets/js/Notification";
import { Chat } from "@/assets/js/Chat";
import SignInModalComponent from "@/components/frontend/auth/SignInModalComponent.vue";
import SignUpModalComponent from "@/components/frontend/auth/SignUpModalComponent.vue";
import ChatUserListComponent from "@/components/frontend/includes/chat/ChatUserListComponent.vue";
import PasswordResetEmailComponent from "@/components/frontend/auth/PasswordResetEmailComponent.vue";
import {GlobalEventBus} from "@/assets/js/eventBus";
import endpoints from "@/assets/js/api";

const removeFromCartRoute = "cart/delete";
const switchRoleRoute = "user/switch/role";
const getNotificationRoute = "notification";
const readNotification = "notification/read";

export default {
  name: "HeaderComponent",
  components: {
    SignInModalComponent,
    SignUpModalComponent,
    ChatUserListComponent,
    PasswordResetEmailComponent,
  },
  props: ["rname", "auth", "carted", "rol"],
  data() {
    return {
      role: this.rol,
      authenticated: false,
      signupRole: "student",
      userDetail: {},
      notifications: [],
      unread_ids: [],
      unread: 0,
      tutors: [],
      cart: [],
      courses: [],
      slots: [],
      subtotal: 0.0,
      total: 0.0,
      quantity: 0,
      show: false,
      display: false,
      showSignIn: false,
      showSignUp: false,
      resetModal: false,
      active_role: "",
      profile: "/assets/profiles/user.jpg",
      roles: [],
      dependents: [],
      query: {
        status: "",
        rating: 0,
        sort: "DESC",

        page: 1,
        limit: 8,
        total: 0,
      },
      isMenuOpen: false,
      openResoursesMenu: false,
      openAboutMenu: false,
      openStudentMenu: false,
      openPoliciesMenu: false,
      openTutorsMenu: false,
    };
  },
  created() {
    let self = this;
    GlobalEventBus.on("refreshcart", function () {
      // self.getCart();
    });
    GlobalEventBus.on("authentication", function () {
      self.getUserDetail();
    });
    GlobalEventBus.on("showLoginForm", function () {
      document.getElementById("signin").click();
    });
    GlobalEventBus.on("showSignUpForm", function (data) {
      self.signupRole = data.role;
      document.getElementById("signup").click();
    });

    // Cart
    // this.getCart()
    if (localStorage.key("cart")) {
      let d = JSON.parse(localStorage.getItem("cart"));
      if (d) {
        self.courses = d.courses ?? [];
        self.slots = d.slots ?? [];
        self.total = d.total ?? 0.0;
      }
    }

    if (this.auth) this.getUserDetail();
    GlobalEventBus.on("notification", function (data) {
      if (data.notification.receiver_id === self.userDetail.id) {
        data.notification.data = JSON.parse(data.notification.data);
        self.notifications.push(data.notification);
        self.notifications.splice(0, 0, data.notification);
        self.unread++;
        self.unread_ids.push(data.uuid);
      } else if (
          data.notification.receiver_role === self.role &&
          data.notification.receiver_id === 0
      ) {
        data.notification.data = JSON.parse(data.notification.data);
        self.notifications.push(data.notification);
        self.notifications.splice(0, 0, data.notification);
        self.unread++;
        self.unread_ids.push(data.uuid);
      }
    });
    // this.role = this.roles
    if (this.auth) {
      this.authenticated = this.auth;
    }
  },
  mounted() {
    if (this.authenticated) {
      Chat();
      Notification();
      this.getNotification();
    }
  },
  methods: {
    async removeFromCart(id) {
      let self = this;
      let query = {};
      query.id = id;
      const { data } = await removeFromCartRoute.list(query);
      if (data.type === "success") {
        self.courses = data.courses;
        self.slots = data.slots;
        self.total = data.total;
        GlobalEventBus.$emit("getCartData", data);
        localStorage.setItem("cart", JSON.stringify(data));
      }
    },
    async getUserDetail() {
      const { data } = await endpoints.getUserDetailRoute.list();
      this.userDetail = data.user;
      this.authenticated = data.authenticated;
      this.dependents = data.dependents;
      this.role = data.role;
      this.profile = data.user.image;
      this.roles = data.roles
          .filter((_) => _.name !== "guardian")
          .filter((_) => _.name !== "dependent");
      this.active_role = data.user.active_role;
      await this.getNotification();
      GlobalEventBus.emit("getUserDetail", data);
      localStorage.setItem("authenticated", data.authenticated);
      localStorage.setItem("detail", JSON.stringify(data));
    },
    async switchRole(role) {
      let formdata = new FormData();
      formdata.append("role", role);
      await switchRoleRoute.post(formdata);
      await this.getUserDetail();
    },
    async getNotification() {
      let self = this;
      const { data } = await getNotificationRoute.list(this.query);
      self.unread = data.unread;
      self.unread_ids = data.unread_ids;
      self.notifications = [];
      data.data.data.forEach(function (noti) {
        self.notifications.push({
          data: JSON.parse(noti.data),
          uuid: noti.uuid,
          created_at: noti.created_at,
          receiver_id: noti.receiver_id,
          receiver_role: noti.receiver_role,
          user_id: noti.user_id,
        });
      });
    },
    async notificationRead() {
      let self = this;
      let formData = new FormData();
      formData.append("uuid", JSON.stringify(self.unread_ids));
      await readNotification.post(formData);
      await this.getNotification();
    },
    redirectToChildDashboard(id) {
      localStorage.setItem("role", this.role);
      localStorage.setItem("dependent_id", id);
      window.location = "/dependent/dashboard";
    },
    checkout() {
      if (this.authenticated) {
        window.location = "/cart/detail";
      } else {
        document.getElementById("signin").click();
      }
    },
    resetPassword() {
      this.showSignIn = false;
      this.showSignUp = false;
      this.resetModal = true;
    },
    logout() {
      localStorage.setItem("authenticated", false);
      localStorage.setItem("cart", JSON.stringify([]));
      localStorage.setItem("detail", JSON.stringify({}));
      window.location.href = "/logout";
    },
    openMenu() {
      this.isMenuOpen = true;
    },
  },
};
</script>

<style scoped>
.header-right-box .dropdown:hover .dropdown-content .item-name a {
  background: white !important;
}
.header-right-box .dropdown:hover .dropdown-content .item-name a:hover {
  background: rgba(0, 0, 0, 0.06) !important;
}
.dropdown .cart-icon.has-alert .shopping-cart {
  display: none;
}
.shopping-cart {
  background: white;
  width: 340px;
  border-radius: 4px;
  padding: 20px;
  right: -15px;
  z-index: 9999999;
  -webkit-box-shadow: 0 0 10px #0000001c;
  box-shadow: 0 0 10px #0000001c;
  position: relative;
}
.header-right-box {
  display: flex;
  align-items: center;
}
.dropdown {
  position: relative;
  display: inline-block;
}
.cart-icon {
  color: #515783;
  font-size: 24px;
  margin-right: 7px;
  float: left;
}
.dropdown .cart-icon.has-alert a {
  display: block;
  text-align: justify;
}
.dropdown .cart-icon.has-alert:hover .shopping-cart {
  display: block;
  position: absolute;
  padding: 5px 15px;
}
.dropdown .cart-icon.has-alert .shopping-cart {
  display: none;
}
.shopping-cart {
  background: white;
  width: 340px;
  border-radius: 4px;
  padding: 20px;
  right: -15px;
  z-index: 9999999;
  -webkit-box-shadow: 0 0 10px #0000001c;
  box-shadow: 0 0 10px #0000001c;
  position: relative;
}

.shopping-cart .product-detail {
  /*border-bottom: 1px solid #d5d5d5;*/
}
.shopping-cart.add_carts .shopping-cart-header {
  border-bottom: 1px solid #d5d5d5;
}
.shopping-cart .shopping-cart-header {
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 5px;
  margin-bottom: 10px;
}
.shopping-cart.add_carts .shopping-cart-header .shopping-cart-total {
  float: unset !important;
  text-align: center;
}
.l-img .fa-chalkboard-teacher {
  color: #812bb2;
}
.lighter-text {
  color: #abb0be;
}
.main-color-text {
  color: #6394f8;
}
nav {
  padding: 20px 0 40px 0;
  /*background: #f8f8f8;*/
  font-size: 16px;
}
.dropdown-menu.sub-menu:hover {
  display: block;
}
nav .navbar-left {
  float: left;
}
nav .navbar-right {
  float: right;
}
nav ul li {
  display: inline;
  padding-left: 20px;
}
nav ul li a {
  color: #777777;
  text-decoration: none;
}
nav ul li a:hover {
  color: black;
}
.badge {
  background-color: #812bb2;
  border-radius: 10px;
  color: white;
  display: inline-block;
  font-size: 12px;
  line-height: 1;
  padding: 3px 7px;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
}
.dropdown .cart-icon.has-alert a {
  display: block;
  text-align: justify;
}
.shopping-cart {
  background: white;
  width: 340px;
  border-radius: 4px;
  padding: 20px;
  right: -15px;
  z-index: 9999999;
  -webkit-box-shadow: 0 0 10px #0000001c;
  box-shadow: 0 0 10px #0000001c;
  position: relative;
}
.shopping-cart .shopping-cart-items {
  padding-top: 20px;
  margin: 0;
  padding: 0;
}
.shopping-cart .shopping-cart-items li {
  margin-bottom: 10px;
  display: block;
  font-size: 16px;
  line-height: normal;
  padding-left: 0;
}
.shopping-cart .shopping-cart-items img {
  float: left;
  margin-right: 12px;
  width: 45px;
  height: 45px;
}
.shopping-cart .shopping-cart-items .item-name {
  display: block;
  padding-top: 2px;
  font-size: 16px;
}
.shopping-cart .shopping-cart-items .item-price {
  color: #6394f8;
  margin-right: 8px;
}
.shopping-cart .shopping-cart-items .item-quantity {
  color: #abb0be;
}
.shopping-cart:after {
  bottom: 100%;
  left: 89%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: white;
  border-width: 8px;
  margin-left: -8px;
}
.cart-icon {
  color: #515783;
  font-size: 24px;
  margin-right: 7px;
  float: left;
}
.button {
  background: #6394f8;
  color: white;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  display: block;
  border-radius: 3px;
  font-size: 16px;
  margin: 25px 0 15px 0;
}
.button:hover {
  background: #729ef9;
}
.clearfix:after {
  content: "";
  display: table;
  clear: both;
}
.wrapper-overlay {
  display: none;
}
.wrapper-overlay.open {
  display: block;
  position: fixed;
  background-color: rgba(35, 35, 35, 0.7);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;
  z-index: 1000;
}
.side-menu .main-menu .nav-bar ul {
  padding-left: 0;
  list-style: none;
  margin: 0;
}
.side-menu .main-menu .nav-bar li {
  border-bottom: 1px solid #ebebeb;
}
.side-menu .main-menu .nav-bar li a {
  padding: 13px 20px 11px;
  text-transform: uppercase;
  font-weight: 700;
  color: #232323;
  font-size: 13px;
  display: inline-block;
  text-decoration: none;
  font-family: "Montserrat-Bold", sans-serif;
}
.side-menu {
  position: fixed;
  height: 100vh;
  top: 0;
  right: -200vw;
  visibility: hidden;
  background: #fff;
}
.side-menu.open {
  visibility: visible;
  right: 0;
  z-index: 99999999;
  -webkit-transition: right 0.5s, visibility 1s;
  -o-transition: right 0.5s, visibility 1s;
  transition: right 0.5s, visibility 1s;
}
.close-button {
  left: inherit;
  right: 0px;
  visibility: hidden;
  position: fixed;
  z-index: 33;
  top: 0;
  width: 40px;
  height: 40px;
  text-align: center;
}
.side-menu.open .close-button {
  visibility: visible;
  right: calc(100% - 80px);
  webkit-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s;
}
.close-button .icon-line {
  background-color: rgba(255, 255, 255, 0);
  display: block;
  width: 24px;
  height: 2px;
  margin: 3px 0;
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
}
.close-button .icon-line .before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 12px;
  left: 8px;
  display: inline-block;
  background-color: #fff;
  width: 24px;
  height: 2px;
  position: absolute;
}
.close-button .icon-line .after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 12px;
  left: 8px;
  display: inline-block;
  background-color: #fff;
  width: 24px;
  height: 2px;
  position: absolute;
}
.resoursesMenu {
  width: 200px;
  background: rgb(244 244 244);
  box-shadow: 0px 4px 22px 3px rgb(0 0 0 / 25%);
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  border-radius: 10px;
  display: none;
}
.aboutMenu {
  width: 200px;
  background: rgb(244 244 244);
  box-shadow: 0px 4px 22px 3px rgb(0 0 0 / 25%);
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  border-radius: 10px;
  display: none;
}
.resoursesMenu.show {
  display: block;
}
.aboutMenu.show {
  display: block;
}
.box-drop {
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.37);
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 2px 5px;
}
.logo-image {
  width: 191px;
  height: 44px;
}
nav.navbar {
  padding: 0 16px;
}

.total-bill-amount {
  justify-content: space-between;
  padding: 0 10px 5px;
}

.shopping-cart.add_carts .total-bill {
  padding: 0;
}

.shopping-cart .shopping-cart-items.notifications li {
  padding-right: 10px;
}

.shopping-cart .shopping-cart-items.notifications li a {
  justify-content: space-between;
  padding: 0;
  display: flex;
  align-items: center;
}

.shopping-cart .view-all-btn {
  margin-bottom: 5px;
}

@media (max-width: 992px) {
  .box-drop {
    background: #ffffff;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.37);
    border-radius: 40px;
    display: flex;
    align-items: center;
    padding: 0;
  }
  .box-drop .user-info-right {
    display: none;
  }
}

@media (max-width: 767px) {
  .logo-image {
    width: 120px;
  }
  nav.navbar {
    padding: 0 10px;
  }

  .dropdown .cart-icon.has-alert:hover .shopping-cart {
    position: fixed;
    right: 0;
    left: 0;
    top: 68px;
    width: 100%;
  }

  .dropdown .cart-icon.has-alert:hover .shopping-cart:after {
    display: none;
  }
}

@media (max-width: 400px) {
  .logo-image {
    width: 110px;
  }
  nav.navbar {
    padding: 0 5px;
  }
}
</style>
