import emitter from 'tiny-emitter'

export const Notification = (role) => {
    if (role !== 'admin' && role !== 'support') {
        JSON.parse(localStorage.getItem('detail')).user
    }
    window.Echo.channel(`Notification`)
        .listen('.ForumQuestionAsked', (e) => {
            if (role === 'support' || role === 'admin'){
              emitter.emit('notification', e)
            }
            else {
              emitter.emit('notification', e)
            }
        });
}
