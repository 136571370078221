<template>
  <div>
    <div class="modal" id="SignInModal" style="display: block; background-color: rgba(0,0,0, 0.4);" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modals-newtheme" role="document">
        <div class="modal-content">
          <div class="modal-body p-6">
            <button v-on:click="$parent.resetModal = !$parent.resetModal" type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <div class="row">
              <div class="col-md-12 col-12">
                <div class="text-center logo_cover">
                  <a  href="#">
                    <picture>
                      <!-- <source media="(max-width:767px)" srcset="/new-theme-images/ilmzone-logo-mobile.png"> -->
                      <img src="@/assets/images/logo.svg" alt="Flowers" width="191">
                    </picture>
                  </a>
                </div>
              </div>
            </div>
            <div class="container">
              <div class="row ">
                <div class="col-md-12 col-12">
                  <div>
                    <!-- <div class="media-btns">
                      <a href="/auth/google" class="btn btn-primary go"><img width="25px" src="/new-theme-images/google-outlined.png" /></a>
                      <a href="javascript:void(0)" class="btn btn-primary fb"><img width="25px" src="/new-theme-images/facebook.png" /></a>
                    </div>
                    <form action="/login" method="post"> -->
                    <form @submit.prevent="validateForm" action="/login" method="post">
                        <h2 class="login">Forgot password</h2>
                      <input type="hidden" name="_token" v-model="credentials.token">
                      <div class="mt-2">
                        <label class="font_size"></label>
                        <input type="email" v-model="credentials.email" name="email" required placeholder="Enter Your Email" class="form-control" />
                      </div>
                      <div class="row">
                        <div class="col-md-12 col-12">
                          <button type="submit" width="100%" class="btn btn-primary width_login">Reset Password</button>
                        </div>
<!--                        <div class="col-md-12 col-12 mt-4 text-center">-->
<!--                          <p class="dont_account text-danger">Don't Have An Account? <span class="sign"><button type="button" data-toggle="modal" data-target="#SignUpModal">Sign Up</button></span> </p>-->
<!--                        </div>-->

                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const passwordResetLinkRoute = 'v1/forgot-password';

export default {
  name: "PasswordResetEmailComponent",
  data() {
    return {
      credentials: {
        email: '',
        password: '',
        token: '',
      }
    }
  },
  mounted() {
  },
  created() {
    let token = document.head.querySelector('meta[name="csrf-token"]');
    this.credentials.token = token.content;
  },
  methods: {
    validateForm() {
      let self = this
      if (!this.credentials.email) {
        self.$toasted.error('Enter Email...')
      }
      else {
        this.sendPasswordResetLink()
      }
    },
    async sendPasswordResetLink() {
      let self = this
      let email = this.credentials.email
      let formData = new FormData
      formData.append('email', email)
      const {data} = await passwordResetLinkRoute.post(formData);
      if (data.type === 'success') {
        self.$toasted.success(data.message)
      }
      else {
        self.$toasted.error(data.message)
      }
    }
  }
}
</script>

<style scoped>
.select{
  background:#4e97d2 ;
  color:white;
}
.select:focus {
  outline: 1px solid #4e97d2;
}
.set{
  display:flex;
}
.dont_account{
  font-size:17px;
}
.dateOfB{
  font-size:17px;
  margin-top:20px;
}
.dont_account .sign a{
  color: #4e97d2b5;
  font-weight: 300;
}
.width_login{
  margin-top:20px;
  width:100%;
}
.forgot{
  margin-top: 14px;
  font-size: 17px;
}
.forgot a{
  color: #4e97d2b5;
  font-weight: 300;
}
.margin_top{
  margin-top:30px;
}
.font_size{
  font-size:16px;
}
.login{
  color:black;
}
.logo_cover{
  padding: 22px;
}
.modal-header{
  border:none;
}
#SignInModal .modals-newtheme .dont_account {
  color: #dc3545!important;
}

</style>
